import React, { useEffect, useMemo, useState } from "react";
import Terminal, { ColorMode, TerminalInput, TerminalOutput } from 'react-terminal-ui';
import './index.css';
import RejjiePic from './images/rejjie.webp';
import BreakfastRoadPic from './images/breakfastroad.webp';
import JasdaCarePic from './images/jasdacare.webp';
import Petespace from './images/petespace.png';
import Deluxe from './images/deluxe.webp';
import AllahAleik from './images/allahaleik.jpg';
import Atp from './images/atp.jpg';
import Munch from './images/munch.jpg';
import Violet from './images/violet.jpg';

function App() {
  const [colorMode, setColorMode] = useState(ColorMode.Dark);
  const [lineData, setLineData] = useState([
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><span className="ascii"> ________  _______  _________  _______   ________  ________  ________  ________  _______          <br />|\   __  \|\  ___ \|\___   ___\\  ___ \ |\   ____\|\   __  \|\   __  \|\   ____\|\  ___ \     <br />\ \  \|\  \ \   __/\|___ \  \_\ \   __/|\ \  \___|\ \  \|\  \ \  \|\  \ \  \___|\ \   __/|<br /> \ \   ____\ \  \_|/__  \ \  \ \ \  \_|/_\ \_____  \ \   ____\ \   __  \ \  \    \ \  \_|/__<br />  \ \  \___|\ \  \_|\ \  \ \  \ \ \  \_|\ \|____|\  \ \  \___|\ \  \ \  \ \  \____\ \  \_|\ \<br />   \ \__\    \ \_______\  \ \__\ \ \_______\____\_\  \ \__\    \ \__\ \__\ \_______\ \_______\<br />    \|__|     \|_______|   \|__|  \|_______|\_________\|__|     \|__|\|__|\|_______|\|_______|<br />                                           \|_________|                                       </span></TerminalOutput>,
    <TerminalOutput><img src={Petespace} className="mobile-ascii" /></TerminalOutput>,
    <TerminalOutput>╔══════════════════════════════╗</TerminalOutput>,
    <TerminalOutput>║       petespace v1.0.2       ║</TerminalOutput>,
    <TerminalOutput>║ (C) 2023 ALL RIGHTS RESERVED ║</TerminalOutput>,
    <TerminalOutput>╚══════════════════════════════╝</TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput> ═══════APPLICATION MENU═══════ </TerminalOutput>,
    <TerminalOutput><a onClick={handleDiscography} className="menu-option">  ╔══════════════════════════╗<br />  ║     (1) DISCOGRAPHY      ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a onClick={handleWebsites} className="menu-option">  ╔══════════════════════════╗<br />  ║      (2) WEBSITES        ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a onClick={handleMixmaster} className="menu-option">  ╔══════════════════════════╗<br />  ║    (3) MIX + MASTER      ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a onClick={handleContact} className="menu-option" >  ╔══════════════════════════╗<br />  ║       (4) CONTACT        ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a href="https://breakfastroad.com/" className="menu-option">  ╔══════════════════════════╗<br />  ║   (9) BREAKFAST ROAD     ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,

    <TerminalOutput></TerminalOutput>,
  ]);

  const [menu, setMenu] = useState([
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput> ═══════APPLICATION MENU═══════ </TerminalOutput>,
    <TerminalOutput><a onClick={handleDiscography} className="menu-option">  ╔══════════════════════════╗<br />  ║     (1) DISCOGRAPHY      ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a onClick={handleWebsites} className="menu-option">  ╔══════════════════════════╗<br />  ║      (2) WEBSITES        ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a onClick={handleMixmaster} className="menu-option">  ╔══════════════════════════╗<br />  ║    (3) MIX + MASTER      ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a onClick={handleContact} className="menu-option" >  ╔══════════════════════════╗<br />  ║       (4) CONTACT        ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a href="https://breakfastroad.com/" className="menu-option">  ╔══════════════════════════╗<br />  ║   (9) BREAKFAST ROAD     ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
  ]);

  function handleMenu() {
    let ld = [<TerminalInput>menu</TerminalInput>];
    ld.push(...menu);
    setLineData((lineData) => [...lineData, ...ld]);
  }

  const [mixmaster, setMixmaster] = useState([
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><img src={Violet} className="album-art" /></TerminalOutput>,
    <TerminalOutput>DYLAN ATLANTIS - VIOLET</TerminalOutput>,
    <TerminalOutput>(MIX + MASTER)</TerminalOutput>,
    <TerminalOutput><a href="https://link.breakfastroad.com/deluxe/">LISTEN NOW</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><img src={Munch} className="album-art" /></TerminalOutput>,
    <TerminalOutput>ZION GARCIA - MUNCH</TerminalOutput>,
    <TerminalOutput>(MIX + MASTER)</TerminalOutput>,
    <TerminalOutput><a href="https://link.breakfastroad.com/deluxe/">LISTEN NOW</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><img src={Atp} className="album-art" /></TerminalOutput>,
    <TerminalOutput>SOLLYY - APPLY THE PRESSURE</TerminalOutput>,
    <TerminalOutput>(STEM MASTERING)</TerminalOutput>,
    <TerminalOutput><a href="https://link.breakfastroad.com/deluxe/">LISTEN NOW</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><a onClick={handleMenu} className="menu-option">  ╔══════════════════════════╗<br />  ║     (0) BACK TO MENU     ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
  ]);

  function handleDiscography() {
    let ld = [<TerminalInput>discography</TerminalInput>];
    ld.push(...discography);
    setLineData((lineData) => [...lineData, ...ld]);
  }

  const [websites, setWebsites] = useState([
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><img src={BreakfastRoadPic} className="website-pic" /></TerminalOutput>,
    <TerminalOutput><span className="wrap">Breakfast Road - Custom LinkTree style landing page</span></TerminalOutput>,
    <TerminalOutput><a href="https://breakfastroad.com/">https://breakfastroad.com</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><img src={RejjiePic} className="website-pic" /></TerminalOutput>,
    <TerminalOutput><span className="wrap">ByRejjie - MacOS inspired storefront and archive</span></TerminalOutput>,
    <TerminalOutput><a href="https://byrejjie.co/">https://byrejjie.co</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><a onClick={handleMenu} className="menu-option">  ╔══════════════════════════╗<br />  ║     (0) BACK TO MENU     ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
  ]);

  function handleWebsites() {
    let ld = [<TerminalInput>websites</TerminalInput>];
    ld.push(...websites);
    setLineData((lineData) => [...lineData, ...ld]);
  }

  const [discography, setDiscography] = useState([
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput> ════════BREAKFAST ROAD════════ </TerminalOutput>, <TerminalOutput></TerminalOutput>,
    <TerminalOutput><img src={Deluxe} className="album-art" /></TerminalOutput>,
    <TerminalOutput>DELUXE (MIXTAPE)</TerminalOutput>,
    <TerminalOutput><a href="https://link.breakfastroad.com/deluxe/">LISTEN NOW</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput> ══════════PETESPACE══════════ </TerminalOutput>, <TerminalOutput></TerminalOutput>,
    <TerminalOutput><img src={AllahAleik} className="album-art" /></TerminalOutput>,
    <TerminalOutput>ALLAH ALEIK [PETESPACE FLIP]</TerminalOutput>,
    <TerminalOutput><a href="https://soundcloud.com/iloveyoupete/allah-aleik-petespace-flip">LISTEN NOW</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><a onClick={handleMenu} className="menu-option">  ╔══════════════════════════╗<br />  ║     (0) BACK TO MENU     ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
  ]);

  function handleMixmaster() {
    let ld = [<TerminalInput>mix + master</TerminalInput>];
    ld.push(...mixmaster);
    setLineData((lineData) => [...lineData, ...ld]);
  }


  const [contact, setContact] = useState([
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput>BOOKING</TerminalOutput>,
    <TerminalOutput><a href="mailto:rachel@breakfastroad.com">RACHEL@BREAKFASTROAD.COM</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput>WEBSITES AND MIXING</TerminalOutput>,
    <TerminalOutput><a href="mailto:contact@petespace.net">CONTACT@PETESPACE.NET</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
  ]);

  function handleContact() {
    let ld = [<TerminalInput>contact</TerminalInput>];
    ld.push(...contact);
    setLineData((lineData) => [...lineData, ...ld]);
  }

  function onInput(input: string) {
    let ld = [...lineData];
    ld.push(<TerminalInput>{input}</TerminalInput>);
    if (input.toLocaleLowerCase().trim() === '1' || input.toLocaleLowerCase().trim() === 'discography') {
      ld.push(...discography);
    } else if (input.toLocaleLowerCase().trim() === '2' || input.toLocaleLowerCase().trim() === 'websites') {
      ld.push(...websites);
    } else if (input.toLocaleLowerCase().trim() === '3' || input.toLocaleLowerCase().trim() === 'mix + master' || input.toLocaleLowerCase().trim() === 'mixmaster' || input.toLocaleLowerCase().trim() === 'mixing' || input.toLocaleLowerCase().trim() === 'mastering' || input.toLocaleLowerCase().trim() === 'mixing + mastering' || input.toLocaleLowerCase().trim() === 'mixing and mastering') {
      ld.push(...mixmaster);
    } else if (input.toLocaleLowerCase().trim() === '4' || input.toLocaleLowerCase().trim() === 'contact') {
      ld.push(...contact);
    } else if (input.toLocaleLowerCase().trim() === '9' || input.toLocaleLowerCase().trim() === 'breakfast road') {
      window.open('https://breakfastroad.com/', '_blank');
    } else if (input.toLocaleLowerCase().trim() === 'menu' || input.toLocaleLowerCase().trim() === 'help' || input.toLocaleLowerCase().trim() === '?' || input.toLocaleLowerCase().trim() === 'h' || input.toLocaleLowerCase().trim() === 'options' || input.toLocaleLowerCase().trim() === 'commands' || input.toLocaleLowerCase().trim() === 'main menu' || input.toLocaleLowerCase().trim() === 'mainmenu' || input.toLocaleLowerCase().trim() === '0') {
      ld.push(...menu);
    } else if (input.toLocaleLowerCase().trim() === 'clear') {
      ld = [];
    } else if (input) {
      ld.push(<TerminalOutput>Unrecognised command. Type 'menu' to redisplay options.</TerminalOutput>);
    }
    setLineData(ld);
  }


  return (
    <div className="container">
      <Terminal
        name='petespace'
        colorMode={colorMode}
        onInput={onInput}
      >
        {lineData}
      </Terminal>
    </div>
  )
}

export default App;
